
import ConversacionService from "@/Services/ConversacionService";
import DialogoMensaje from "../componentes/DialogoMensaje.vue";
export default {
  name: "area-mensajes",
  components: { DialogoMensaje },
  props: { conversacionObj: { type: Object, default: () => {} } },
  mounted() {},
  data() {
    return {
      mensaje: "",
    };
  },
  watch: {
    "conversacionObj.lead_id"() {
      this.mensaje = "";
      this.$nextTick(() => this.$refs.inputMensaje.focus());
    },
  },
  methods: {
    async enviarMensaje() {
      if (!this.mensaje) return;

      let datos = {
        numeroContacto: this.conversacionObj.telefono,
        mensaje: this.mensaje,
        leadId: this.conversacionObj.lead_id,
        plataforma: "whatsapp",
      };
      this.mensaje = "";
      // this.loader(true);
      await ConversacionService.agregarMensaje(datos)
        .then(async () => {
          // let respuesta = resp.datos;
          // let mensaje = {
          //   mensajeFecha: respuesta.registro_fecha,
          //   leadId: respuesta.lead_id,
          //   mensaje: JSON.stringify(respuesta),
          // };
          // this.$emit("mensaje-enviado", mensaje);
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          // this.loader(false);
        });
    },
    cargarMensajesAnteriores() {
      this.$emit("cargar-mensajes-anteriores");
    },
  },
};
