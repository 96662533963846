
export default {
  name: "card-conversacion",
  props: {
    conversacionObj: { type: Object, required: true },
    activa: { type: Boolean, default: false },
  },
  data() {
    return {
      zonaHoraria: localStorage.getItem("zonaHoraria"),
    };
  },
  computed: {
    ultimoMensajeFecha() {
      let fechaActual = this.dayjs(this.fechaActual());
      let ultimoMensajeFecha = this.dayjs(
        this.conversacionObj.ultimo_mensaje_fecha
      );
      if (fechaActual.isSame(ultimoMensajeFecha, "day")) {
        return ultimoMensajeFecha.format("HH:mm");
      }
      return this.dayjs(this.conversacionObj.ultimo_mensaje_fecha).fromNow();
    },
    existenMensajesNoVistos() {
      if (_.isEmpty(this.conversacionObj.mensajes)) return false;
      let ultimoMensajeRecibido = this.conversacionObj.mensajes.find(
        (men) => men.tipo == "Recibido"
      );
      return (
        ultimoMensajeRecibido.mensaje_plataforma_id >
        this.conversacionObj.ultimo_mensaje_visto_id
      );
    },
    cantidadMensajeNoVistos() {
      let mensajesNoVistos = this.conversacionObj.mensajes.filter(
        (men) =>
          men.mensaje_plataforma_id >
            this.conversacionObj.ultimo_mensaje_visto_id &&
          men.tipo == "Recibido"
      );
      return mensajesNoVistos.length;
    },
  },
  methods: {
    abrirConversacion() {
      this.$emit("abrir-conversacion", this.conversacionObj);
    },
  },
};
