import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "productos", "listarGestor"),
  listarDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "productos", "listarDetalle"),
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "productos", "obtenerDetalle"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "productos",
      "obtenerConcentradoOpciones"
    ),
  // POST
  agregarProducto: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "productos", "agregar"),
  editarProducto: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "productos", "editar"),
  eliminarProducto: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "productos", "eliminar"),
  actualizarStatus: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "productos", "actualizarStatus"),
};
