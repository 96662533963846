
import ConversacionService from "@/Services/ConversacionService";
import CardConversacion from "./componentes/CardConversacion.vue";
import AreaMensajes from "./componentes/AreaMensajes.vue";
import AreaDetalleContacto from "./componentes/AreaDetalleContacto.vue";

export default {
  components: { CardConversacion, AreaMensajes, AreaDetalleContacto },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Conversaciones" }],
      numero: "",
      mensaje: "",
      filtros: { ordenar: "registro_fecha_desc" },
      contactos: JSON.parse(localStorage.getItem("contactos")),
      conversaciones: JSON.parse(localStorage.getItem("conversaciones")),
      conversacionObj: {},
    };
  },
  mounted() {
    this.cargaInicial();
  },
  created() {
    this.$conversacionEventBus.$on(
      "actualizar-conversaciones-mensaje-recibido",
      this.manejarActualizacionConversaciones
    );
    this.$conversacionEventBus.$on(
      "agregar-conversaciones-anteriores",
      this.manejarActualizacionConversacionesAnteriores
    );
    this.$conversacionEventBus.$on(
      "agregar-mensajes-anteriores",
      this.manejarActualizacionMensajesAnteriores
    );
  },
  beforeDestroy() {
    this.conversacionObj = {};
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.cargarConversaciones();
      this.loader(false);
    },
    async cargarConversaciones() {
      // Se valida cache
      if (_.isEmpty(this.conversaciones) || _.isEmpty(this.contactos)) {
        this.$conversacionEventBus.$emit("cargar-conversaciones");
        return;
      }
    },
    cargarConversacionesAnteriores() {
      this.$conversacionEventBus.$emit("cargar-conversaciones-anteriores");
    },
    async actualizarUltimoMensajeVisto() {
      if (_.isEmpty(this.conversacionObj.mensajes)) return;
      let datos = {
        leadId: this.conversacionObj.lead_id,
        plataforma: this.conversacionObj.plataforma,
        ultimoMensajeVistoId: this.conversacionObj.ultimo_mensaje_visto_id,
      };
      await ConversacionService.actualizarUltimoMensajeVisto(datos)
        .then(async () => {
          this.$conversacionEventBus.$emit(
            "actualizar-conversacion-ultimo-mensaje-visto",
            this.conversacionObj
          );
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {});
    },
    async abrirConversacion(conversacionObj) {
      // Se valida conversacion abierta
      if (
        conversacionObj.lead_id == this.conversacionObj.lead_id &&
        conversacionObj.plataforma == this.conversacionObj.plataforma
      )
        return;

      // Se actualiza conversacion obj
      this.conversacionObj = conversacionObj;

      // Se valida ultimo mensaje visto
      let ultimoMensajeRecibido = conversacionObj.mensajes.find(
        (men) => men.tipo == "Recibido"
      );
      if (
        ultimoMensajeRecibido.mensaje_plataforma_id ==
        conversacionObj.ultimo_mensaje_visto_id
      )
        return;

      // Se actualiza ultimo mensaje visto
      this.conversacionObj.ultimo_mensaje_visto_id =
        this.conversacionObj.mensajes[0].mensaje_plataforma_id;
      await this.actualizarUltimoMensajeVisto();
    },
    async manejarActualizacionConversaciones(conversaciones) {
      let ultimaConversacion = conversaciones[0];
      this.conversaciones = conversaciones;
      if (!_.isEmpty(this.conversacionObj)) {
        // Se actualiza conversacion abierta
        let conversacion = this.conversaciones.find(
          (conv) => conv.lead_id == this.conversacionObj.lead_id
        );
        this.conversacionObj = _.cloneDeep(conversacion);

        // Se valida ultimo mensaje leido
        if (this.conversacionObj.lead_id == ultimaConversacion.lead_id) {
          let ultimoMensajeRecibido = ultimaConversacion.mensajes.find(
            (men) => men.tipo == "Recibido"
          );
          if (
            ultimoMensajeRecibido.mensaje_plataforma_id >
            this.conversacionObj.ultimo_mensaje_visto_id
          ) {
            console.log("actualizando ultimo mensaje visto");
            this.conversacionObj.ultimo_mensaje_visto_id =
              ultimoMensajeRecibido.mensaje_plataforma_id;
            await this.actualizarUltimoMensajeVisto();
          }
        }
      }
    },
    manejarActualizacionConversacionesAnteriores(conversaciones) {
      this.conversaciones = conversaciones;
    },
    cargarMensajesAnteriores() {
      let leadId = this.conversacionObj.lead_id;
      let plataforma = this.conversacionObj.plataforma;
      let primerMensajeId =
        this.conversacionObj.mensajes[this.conversacionObj.mensajes.length - 1]
          .mensaje_plataforma_id;
      this.$conversacionEventBus.$emit(
        "cargar-mensajes-anteriores",
        leadId,
        primerMensajeId,
        plataforma
      );
    },
    manejarActualizacionMensajesAnteriores(conversaciones, mensajes) {
      this.conversaciones = conversaciones;
      this.conversacionObj.mensajes =
        this.conversacionObj.mensajes.concat(mensajes);
    },
  },
};
