
import LeadService from "@/Services/LeadService";
import CardOportunidadDetalleBasico from "@/views/oportunidades/componentes/CardOportunidadDetalleBasico.vue";
import ModalOportunidadAgregar from "@/views/oportunidades/componentes/OportunidadAgregarModal.vue";
import AreaEtiquetas from "@/views/leads/components/AreaEtiquetas.vue";
export default {
  name: "area-detalle-contacto",
  components: {
    CardOportunidadDetalleBasico,
    ModalOportunidadAgregar,
    AreaEtiquetas,
  },
  props: {
    contactoId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      mostrarContenido: false,
      contactoObj: {},
      telefonos: [],
      oportunidades: [],
      modalAgregarOportunidad: false,
      agenteOpciones: [],
      etiquetasOpciones: [],
      etiquetasContacto: [],
    };
  },
  mounted() {
    this.obtenerDetalleContacto();
  },
  watch: {
    contactoId() {
      this.obtenerDetalleContacto();
    },
  },
  methods: {
    async obtenerDetalleContacto() {
      this.mostrarContenido = false;
      let datos = {
        leadId: this.contactoId,
      };
      await LeadService.obtenerDetalleConversacion(datos)
        .then((resp) => {
          this.contactoObj = resp.info;
          this.telefonos = resp.telefonos;
          this.oportunidades = resp.oportunidades;
          this.etiquetasOpciones = resp.etiquetas;
          this.etiquetasContacto = resp.etiquetasLead;
          this.agenteOpciones = resp.agentes;
          this.telefonos.unshift({ telefono: this.contactoObj.telefono });

          this.mostrarContenido = true;
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        });
    },
    async abrirModalAgregarOportunidad() {
      this.modalAgregarOportunidad = true;
    },
    cerrarModalAgregarOportunidad() {
      this.modalAgregarOportunidad = false;
    },
    async manejarExitoAltaOportunidad(respuesta) {
      this.cerrarModalAgregarOportunidad();
      await this.obtenerDetalleContacto();
      this.mostrarAlerta(respuesta.mensaje, "exito");
    },
    manejarLoader(loader) {
      this.mostrarContenido = !loader;
    },
    async manejarEtiquetaAgregada() {
      await this.obtenerDetalleContacto();
    },
    async manejarEtiquetaEliminada() {
      await this.obtenerDetalleContacto();
    },
  },
};
