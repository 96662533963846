import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  listarGestor: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(filtros, "mensajesPlataformas"),
  obtenerUltimosMensajes: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(
      filtros,
      "mensajesPlataformas",
      null,
      "ultimos-mensajes"
    ),
  obtenerConversacionesAnteriores: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(
      filtros,
      "mensajesPlataformas",
      null,
      "conversaciones-anteriores"
    ),
  obtenerConversacion: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(
      filtros,
      "mensajesPlataformas",
      null,
      "conversacion"
    ),
  obtenerMensajesAnteriores: (filtros) =>
    RepoUtils.ejecutarPeticionGetV2(
      filtros,
      "mensajesPlataformas",
      null,
      "mensajes-anteriores"
    ),

  // POST
  agregarMensaje: (data) =>
    RepoUtils.ejecutarPeticionPostV2(data, "mensajesPlataformas"),
  actualizarUltimoMensajeVisto: (data) =>
    RepoUtils.ejecutarPeticionPostV2(
      data,
      "mensajesPlataformas",
      null,
      "ultimo-mensaje-visto"
    ),
};
