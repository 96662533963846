
export default {
  name: "dialog-mensaje",
  props: {
    tipo: {
      type: String,
      required: true,
    },
    mensaje: {
      type: String,
      required: true,
    },
    fecha: {
      type: String,
      required: true,
    },
    statusWa: {
      type: String,
      default: "",
    },
  },
  computed: {
    obtenerClase() {
      if (this.tipo == "Recibido") return "mensaje-recibido-contenedor";
      return "mensaje-enviado-contenedor";
    },
    fechaFormateada() {
      let fechaActual = this.dayjs(this.fechaActual());
      let fechaMensaje = this.dayjs(this.fecha);
      if (fechaActual.isSame(fechaMensaje, "day")) {
        return fechaMensaje.format("HH:mm");
      }
      return this.formatearFecha(this.fecha, 2, true);
    },
  },
};
