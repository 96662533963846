
import OportunidadService from "../../../Services/OportunidadService";
import ProductoService from "../../../Services/ProductoService";
import LeadService from "../../../Services/LeadService";

export default {
  name: "oportunidad-agregar-modal",
  props: {
    modelo: {
      type: Boolean,
      required: true,
    },
    agenteOpciones: {
      type: Array,
      required: true,
    },
    contactoObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      agregarOportunidadObj: {
        contactoId: "",
        contactoNombre: "",
        contactoTelefono: "",
        contactoEmail: "",
        articuloId: "",
        articuloNombre: "",
        articuloDescripcion: "",
        articuloValorEstimado: "",
        valorVenta: "",
        posibleFechaCierre: "",
        descripcion: "",
        agenteAsignadoId: Number(localStorage.getItem("autorId")),
        comentarios: "",
      },
      contactos: [],
      columnasContactos: [
        {
          name: "folio",
          label: "#Contacto",
          field: "folio_lead",
          style: "width: 5%",
          align: "center",
        },
        {
          name: "nombre",
          label: "Nombre",
          field: "nombre_completo",
          style: "width: 15%",
          align: "left",
        },
        {
          name: "telefono",
          label: "Telefono",
          field: "telefono",
          style: "width: 10%",
          align: "left",
          format: (val) => this.formatearTelefono(val, 1, false),
        },
        {
          name: "email",
          label: "Email",
          field: "email",
          style: "width: 15%",
          align: "left",
        },
        {
          name: "registroFecha",
          label: "Registro",
          field: "registro_fecha",
          style: "width: 10%",
          align: "left",
          format: (val) => this.formatearFecha(val, 2),
        },
      ],
      articulos: [],
      columnasArticulos: [
        {
          name: "folio",
          label: "#Artículo",
          field: "folio_articulo",
          style: "width: 5%",
          align: "center",
        },
        {
          name: "titulo",
          label: "Título",
          field: "titulo",
          style: "width: 15%",
          align: "left",
        },
        {
          name: "categoria",
          label: "Categoría",
          field: "categoria_nombre",
          style: "width: 10%",
          align: "left",
        },
        {
          name: "subcategoria",
          label: "Subcategoría",
          field: "subcategoria_nombre",
          style: "width: 10%",
          align: "left",
        },
        {
          name: "marca",
          label: "Marca",
          field: "marca_nombre",
          style: "width: 10%",
          align: "left",
        },
        {
          name: "registroFecha",
          label: "Registro",
          field: "registro_fecha",
          style: "width: 10%",
          align: "left",
          format: (val) => this.formatearFecha(val, 2),
        },
      ],
      tipoSeleccion: 0,
      modalSeleccionDatos: [],
      modalSeleccionColumnas: [],
      modalSeleccionBusqueda: "",
      modalSeleccion: false,
      modalSeleccionTitulo: "",
    };
  },
  watch: {
    modelo() {
      if (this.modelo && !_.isEmpty(this.contactoObj)) {
        this.tipoSeleccion = 1;
        this.manejarOpcionSeleccionada(this.contactoObj);
      }
    },
  },
  computed: {
    contactoSeleccionado() {
      return this.agregarOportunidadObj.contactoId == "" ? false : true;
    },
    articuloSeleccionado() {
      return this.agregarOportunidadObj.articuloId == "" ? false : true;
    },
  },
  methods: {
    async listarContactos(loader = true, busqueda = "") {
      if (loader) this.loader(true);
      let filtros = {
        statusLead: [
          this.constantesStatusCadena.lead,
          this.constantesStatusCadena.cliente,
        ],
        ordenar: "nombre_asc",
        busqueda: "",
      };
      if (this.agregarOportunidadObj.contactoId == "") {
        filtros.busqueda = this.agregarOportunidadObj.contactoNombre;
        this.modalSeleccionBusqueda = _.cloneDeep(
          this.agregarOportunidadObj.contactoNombre
        );
      } else if (busqueda != "") {
        filtros.busqueda = busqueda;
      }

      await LeadService.listarDetalle(filtros)
        .then((resp) => {
          this.contactos = resp;
          this.abrirModalSeleccionarContacto();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async listarArticulos(loader = true, busqueda = "") {
      if (loader) this.loader(true);
      let filtros = {
        status: [200],
        ordenar: "titulo_asc",
        busqueda: "",
      };
      if (this.agregarOportunidadObj.articuloId == "") {
        filtros.busqueda = this.agregarOportunidadObj.articuloNombre;
        this.modalSeleccionBusqueda = _.cloneDeep(
          this.agregarOportunidadObj.articuloNombre
        );
      } else if (busqueda != "") {
        filtros.busqueda = busqueda;
      }
      await ProductoService.listarDetalle(filtros)
        .then((resp) => {
          this.articulos = resp;
          this.abrirModalSeleccionarArticulo();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async agregarOportunidad(loader = true) {
      if (this.agregarOportunidadObj.contactoId == "") {
        this.mostrarAlerta("Selecciona un contacto para continuar.", "error");
        this.$refs.inputContactoNombre.$el.focus();
        return;
      }
      if (this.agregarOportunidadObj.articuloId == "") {
        this.mostrarAlerta("Selecciona un artículo para continuar.", "error");
        this.$refs.inputArticuloNombre.$el.focus();
        return;
      }
      let datos = {
        ...this.agregarOportunidadObj,
        posibleFechaCierre: this.dayjs(
          this.agregarOportunidadObj.posibleFechaCierre,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      if (loader) this.loader(true);
      await OportunidadService.crear(datos)
        .then((resp) => {
          if (loader) this.loader(false);
          this.$emit("exito", resp);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        })
        .then(() => {});
    },
    cerrarModalAgregar() {
      this.agregarOportunidadObj = {
        contactoId: "",
        contactoNombre: "",
        contactoTelefono: "",
        contactoEmail: "",
        articuloId: "",
        articuloNombre: "",
        articuloDescripcion: "",
        articuloValorEstimado: "",
        valorVenta: "",
        posibleFechaCierre: "",
        descripcion: "",
        agenteAsignadoId: Number(localStorage.getItem("autorId")),
        comentarios: "",
      };
      this.$emit("cerrar-modal-agregar");
    },
    abrirModalSeleccionarContacto() {
      this.tipoSeleccion = 1;
      this.modalSeleccionDatos = _.cloneDeep(this.contactos);
      this.modalSeleccionColumnas = _.cloneDeep(this.columnasContactos);
      this.modalSeleccionTitulo = "Búsqueda de contacto";
      this.modalSeleccion = true;
    },
    abrirModalSeleccionarArticulo() {
      this.tipoSeleccion = 2;
      this.modalSeleccionDatos = _.cloneDeep(this.articulos);
      this.modalSeleccionColumnas = _.cloneDeep(this.columnasArticulos);
      this.modalSeleccionTitulo = "Búsqueda de artículo";
      this.modalSeleccion = true;
    },
    manejarCierreModalSeleccion() {
      if (this.tipoSeleccion == 1) {
        this.contactos = [];
        this.$nextTick(() => this.$refs.inputArticuloNombre.$el.focus());
      } else if (this.tipoSeleccion == 2) {
        this.articulos = [];
        this.$nextTick(() => this.$refs.inputValorOportunidad.$el.focus());
      }
      this.modalSeleccionDatos = [];
      this.modalSeleccionTitulo = "";
      this.modalSeleccionBusqueda = "";
      this.modalSeleccion = false;
      this.tipoSeleccion = 0;
    },
    manejarBusquedaModalSeleccion(busqueda) {
      this.modalSeleccionBusqueda = busqueda;
      if (this.tipoSeleccion === 1) {
        if (this.agregarOportunidadObj.contactoId == "")
          this.agregarOportunidadObj.contactoNombre = busqueda;
        this.listarContactos(true, busqueda);
      } else if (this.tipoSeleccion === 2) {
        if (this.agregarOportunidadObj.articuloId == "")
          this.agregarOportunidadObj.articuloNombre = busqueda;
        this.listarArticulos(true, busqueda);
      }
    },
    manejarOpcionSeleccionada(item) {
      if (this.tipoSeleccion === 1) {
        this.agregarOportunidadObj.contactoId = item.lead_id;
        this.agregarOportunidadObj.contactoNombre = item.nombre_completo;
        this.agregarOportunidadObj.contactoTelefono = item.telefono;
        this.agregarOportunidadObj.contactoEmail = item.email;
      } else if (this.tipoSeleccion === 2) {
        this.agregarOportunidadObj.articuloId = item.articulo_id;
        this.agregarOportunidadObj.articuloNombre = item.titulo;
        this.agregarOportunidadObj.articuloDescripcion = item.descripcion;
        this.agregarOportunidadObj.articuloValorEstimado =
          item.valor_estimado_minimo;
        this.agregarOportunidadObj.valorVenta = item.valor_estimado_minimo;
      }
      this.manejarCierreModalSeleccion();
    },
  },
};
